import React from 'react'

const ArrowTop = () => {
    return (
        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 0L8 6H0L4 0Z" fill="#c7c7c7"/>
        </svg>
    )
}

export default ArrowTop